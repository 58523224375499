import validate from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  aanvragen: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/aanvragen.ts"),
  auth: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/auth.ts"),
  company: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/company.ts"),
  currentfund: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/currentfund.ts"),
  fund: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/fund.ts"),
  iban: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/iban.ts"),
  naw: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/naw.ts"),
  onfido: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/onfido.ts"),
  partnerplan: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/partnerplan.ts"),
  qrcode: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/qrcode.ts"),
  sign: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/middleware/sign.ts")
}