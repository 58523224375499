import { default as _91choice_931gT80OeeZ0Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexXAtxkwNSpeMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexz7EK0SJvHTMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexdbXhqMMXKAMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/index.vue?macro=true";
import { default as indexPQL8YiKg8nMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoordsBX7iXbFZpMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenaIdtbUXWtRMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexOImnUx5pDbMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexRzaWGXdcj8Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexCodJ28e8pkMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexLIeUrT6WV5Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexFwcbFUt0iwMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexWdoxNxs5msMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indextWGZSDIX94Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexAMcPX66tPIMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexKhYhRoxUlDMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexPOBDsHelqrMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexWNe1AU1T1vMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexEDh208JX1lMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexQJAqq9ceQiMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mobile/index.vue?macro=true";
import { default as indexAjxkU7OaUrMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexZkV9dAwSCVMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexBSPLD4XvvlMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexv3bvt83F6RMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as index1h97lcFD8MMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as index3wCvDFwL3dMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexX1c9MzGQIdMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorWpPjfAzT7oMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexn1ZcwFd5gHMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodeohf1PPLgsdMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesRrrAUAn0skMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93Ck9jzH7tirMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93f3JBPqhoiFMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraag6H2uhU0ON3Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93E443OsZZC8Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93h8KVVYVlFaMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as index0yGUzld5FEMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91fundKey_93nGbTuTv8wBMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagyYZbbDZiA0Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexAnYGE8AzARMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_937jrInCWz33Meta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexjf7LkAdFtbMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93bYN5fhXzCGMeta } from "/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_931gT80OeeZ0Meta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_931gT80OeeZ0Meta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_931gT80OeeZ0Meta || {},
    alias: _91choice_931gT80OeeZ0Meta?.alias || [],
    redirect: _91choice_931gT80OeeZ0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexXAtxkwNSpeMeta?.name ?? "aanbod",
    path: indexXAtxkwNSpeMeta?.path ?? "/aanbod",
    meta: indexXAtxkwNSpeMeta || {},
    alias: indexXAtxkwNSpeMeta?.alias || [],
    redirect: indexXAtxkwNSpeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexz7EK0SJvHTMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexz7EK0SJvHTMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexz7EK0SJvHTMeta || {},
    alias: indexz7EK0SJvHTMeta?.alias || [],
    redirect: indexz7EK0SJvHTMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdbXhqMMXKAMeta?.name ?? "index",
    path: indexdbXhqMMXKAMeta?.path ?? "/",
    meta: indexdbXhqMMXKAMeta || {},
    alias: indexdbXhqMMXKAMeta?.alias || [],
    redirect: indexdbXhqMMXKAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexPQL8YiKg8nMeta?.name ?? "login",
    path: indexPQL8YiKg8nMeta?.path ?? "/login",
    meta: indexPQL8YiKg8nMeta || {},
    alias: indexPQL8YiKg8nMeta?.alias || [],
    redirect: indexPQL8YiKg8nMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoordsBX7iXbFZpMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoordsBX7iXbFZpMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoordsBX7iXbFZpMeta || {},
    alias: reset_45wachtwoordsBX7iXbFZpMeta?.alias || [],
    redirect: reset_45wachtwoordsBX7iXbFZpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenaIdtbUXWtRMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenaIdtbUXWtRMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenaIdtbUXWtRMeta || {},
    alias: wachtwoord_45vergetenaIdtbUXWtRMeta?.alias || [],
    redirect: wachtwoord_45vergetenaIdtbUXWtRMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexOImnUx5pDbMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexOImnUx5pDbMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexOImnUx5pDbMeta || {},
    alias: indexOImnUx5pDbMeta?.alias || [],
    redirect: indexOImnUx5pDbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexRzaWGXdcj8Meta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexRzaWGXdcj8Meta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexRzaWGXdcj8Meta || {},
    alias: indexRzaWGXdcj8Meta?.alias || [],
    redirect: indexRzaWGXdcj8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCodJ28e8pkMeta?.name ?? "mijn-aanvragen",
    path: indexCodJ28e8pkMeta?.path ?? "/mijn-aanvragen",
    meta: indexCodJ28e8pkMeta || {},
    alias: indexCodJ28e8pkMeta?.alias || [],
    redirect: indexCodJ28e8pkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexLIeUrT6WV5Meta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexLIeUrT6WV5Meta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexLIeUrT6WV5Meta || {},
    alias: indexLIeUrT6WV5Meta?.alias || [],
    redirect: indexLIeUrT6WV5Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexFwcbFUt0iwMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexFwcbFUt0iwMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexFwcbFUt0iwMeta || {},
    alias: indexFwcbFUt0iwMeta?.alias || [],
    redirect: indexFwcbFUt0iwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdoxNxs5msMeta?.name ?? "mijn-organisaties",
    path: indexWdoxNxs5msMeta?.path ?? "/mijn-organisaties",
    meta: indexWdoxNxs5msMeta || {},
    alias: indexWdoxNxs5msMeta?.alias || [],
    redirect: indexWdoxNxs5msMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indextWGZSDIX94Meta?.name ?? "mijn-partnerplan-token-choice",
    path: indextWGZSDIX94Meta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indextWGZSDIX94Meta || {},
    alias: indextWGZSDIX94Meta?.alias || [],
    redirect: indextWGZSDIX94Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMcPX66tPIMeta?.name ?? "mijn-partnerplan",
    path: indexAMcPX66tPIMeta?.path ?? "/mijn-partnerplan",
    meta: indexAMcPX66tPIMeta || {},
    alias: indexAMcPX66tPIMeta?.alias || [],
    redirect: indexAMcPX66tPIMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexKhYhRoxUlDMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexKhYhRoxUlDMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexKhYhRoxUlDMeta || {},
    alias: indexKhYhRoxUlDMeta?.alias || [],
    redirect: indexKhYhRoxUlDMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexPOBDsHelqrMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexPOBDsHelqrMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexPOBDsHelqrMeta || {},
    alias: indexPOBDsHelqrMeta?.alias || [],
    redirect: indexPOBDsHelqrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexWNe1AU1T1vMeta?.name ?? "mijn-profiel",
    path: indexWNe1AU1T1vMeta?.path ?? "/mijn-profiel",
    meta: indexWNe1AU1T1vMeta || {},
    alias: indexWNe1AU1T1vMeta?.alias || [],
    redirect: indexWNe1AU1T1vMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDh208JX1lMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexEDh208JX1lMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexEDh208JX1lMeta || {},
    alias: indexEDh208JX1lMeta?.alias || [],
    redirect: indexEDh208JX1lMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexQJAqq9ceQiMeta?.name ?? "mobile",
    path: indexQJAqq9ceQiMeta?.path ?? "/mobile",
    meta: indexQJAqq9ceQiMeta || {},
    alias: indexQJAqq9ceQiMeta?.alias || [],
    redirect: indexQJAqq9ceQiMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexAjxkU7OaUrMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexAjxkU7OaUrMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexAjxkU7OaUrMeta || {},
    alias: indexAjxkU7OaUrMeta?.alias || [],
    redirect: indexAjxkU7OaUrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexZkV9dAwSCVMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexZkV9dAwSCVMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexZkV9dAwSCVMeta || {},
    alias: indexZkV9dAwSCVMeta?.alias || [],
    redirect: indexZkV9dAwSCVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexBSPLD4XvvlMeta?.name ?? "mogelijk-fonds-id",
    path: indexBSPLD4XvvlMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexBSPLD4XvvlMeta || {},
    alias: indexBSPLD4XvvlMeta?.alias || [],
    redirect: indexBSPLD4XvvlMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexv3bvt83F6RMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexv3bvt83F6RMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexv3bvt83F6RMeta || {},
    alias: indexv3bvt83F6RMeta?.alias || [],
    redirect: indexv3bvt83F6RMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: index1h97lcFD8MMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: index1h97lcFD8MMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: index1h97lcFD8MMeta || {},
    alias: index1h97lcFD8MMeta?.alias || [],
    redirect: index1h97lcFD8MMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: index3wCvDFwL3dMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: index3wCvDFwL3dMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: index3wCvDFwL3dMeta || {},
    alias: index3wCvDFwL3dMeta?.alias || [],
    redirect: index3wCvDFwL3dMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexX1c9MzGQIdMeta?.name ?? "mogelijk-fonds",
    path: indexX1c9MzGQIdMeta?.path ?? "/mogelijk-fonds",
    meta: indexX1c9MzGQIdMeta || {},
    alias: indexX1c9MzGQIdMeta?.alias || [],
    redirect: indexX1c9MzGQIdMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorWpPjfAzT7oMeta?.name ?? "ockto-hash-error",
    path: errorWpPjfAzT7oMeta?.path ?? "/ockto/:hash()/error",
    meta: errorWpPjfAzT7oMeta || {},
    alias: errorWpPjfAzT7oMeta?.alias || [],
    redirect: errorWpPjfAzT7oMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexn1ZcwFd5gHMeta?.name ?? "ockto-hash",
    path: indexn1ZcwFd5gHMeta?.path ?? "/ockto/:hash()",
    meta: indexn1ZcwFd5gHMeta || {},
    alias: indexn1ZcwFd5gHMeta?.alias || [],
    redirect: indexn1ZcwFd5gHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodeohf1PPLgsdMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodeohf1PPLgsdMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodeohf1PPLgsdMeta || {},
    alias: qrcodeohf1PPLgsdMeta?.alias || [],
    redirect: qrcodeohf1PPLgsdMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesRrrAUAn0skMeta?.name ?? "ockto-hash-succes",
    path: succesRrrAUAn0skMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesRrrAUAn0skMeta || {},
    alias: succesRrrAUAn0skMeta?.alias || [],
    redirect: succesRrrAUAn0skMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Ck9jzH7tirMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_93Ck9jzH7tirMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93Ck9jzH7tirMeta || {},
    alias: _91token_93Ck9jzH7tirMeta?.alias || [],
    redirect: _91token_93Ck9jzH7tirMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f3JBPqhoiFMeta?.name ?? "onboarding-slug",
    path: _91slug_93f3JBPqhoiFMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93f3JBPqhoiFMeta || {},
    alias: _91slug_93f3JBPqhoiFMeta?.alias || [],
    redirect: _91slug_93f3JBPqhoiFMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraag6H2uhU0ON3Meta?.name ?? "onboarding-aanvraag",
    path: aanvraag6H2uhU0ON3Meta?.path ?? "/onboarding/aanvraag",
    meta: aanvraag6H2uhU0ON3Meta || {},
    alias: aanvraag6H2uhU0ON3Meta?.alias || [],
    redirect: aanvraag6H2uhU0ON3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93E443OsZZC8Meta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93E443OsZZC8Meta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93E443OsZZC8Meta || {},
    alias: _91experience_93E443OsZZC8Meta?.alias || [],
    redirect: _91experience_93E443OsZZC8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93h8KVVYVlFaMeta?.name ?? "onfido-token",
    path: _91token_93h8KVVYVlFaMeta?.path ?? "/onfido/:token()",
    meta: _91token_93h8KVVYVlFaMeta || {},
    alias: _91token_93h8KVVYVlFaMeta?.alias || [],
    redirect: _91token_93h8KVVYVlFaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: index0yGUzld5FEMeta?.name ?? "privacy-verklaring",
    path: index0yGUzld5FEMeta?.path ?? "/privacy-verklaring",
    meta: index0yGUzld5FEMeta || {},
    alias: index0yGUzld5FEMeta?.alias || [],
    redirect: index0yGUzld5FEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93nGbTuTv8wBMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93nGbTuTv8wBMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93nGbTuTv8wBMeta || {},
    alias: _91fundKey_93nGbTuTv8wBMeta?.alias || [],
    redirect: _91fundKey_93nGbTuTv8wBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagyYZbbDZiA0Meta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagyYZbbDZiA0Meta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagyYZbbDZiA0Meta || {},
    alias: aanvraagyYZbbDZiA0Meta?.alias || [],
    redirect: aanvraagyYZbbDZiA0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexAnYGE8AzARMeta?.name ?? "sso-exchangeToken-onboarding",
    path: indexAnYGE8AzARMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexAnYGE8AzARMeta || {},
    alias: indexAnYGE8AzARMeta?.alias || [],
    redirect: indexAnYGE8AzARMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937jrInCWz33Meta?.name ?? "sso-token-id",
    path: _91id_937jrInCWz33Meta?.path ?? "/sso/:token()/:id()",
    meta: _91id_937jrInCWz33Meta || {},
    alias: _91id_937jrInCWz33Meta?.alias || [],
    redirect: _91id_937jrInCWz33Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjf7LkAdFtbMeta?.name ?? "sso-token",
    path: indexjf7LkAdFtbMeta?.path ?? "/sso/:token()",
    meta: indexjf7LkAdFtbMeta || {},
    alias: indexjf7LkAdFtbMeta?.alias || [],
    redirect: indexjf7LkAdFtbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93bYN5fhXzCGMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93bYN5fhXzCGMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93bYN5fhXzCGMeta || {},
    alias: _91hash_93bYN5fhXzCGMeta?.alias || [],
    redirect: _91hash_93bYN5fhXzCGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241010131452/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]